export default class Slideshow {
  constructor() {
    $(window).on('load', () => {
      $('.slideshow').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 3,
        centerMode: true,
        focusOnSelect: true,
        variableWidth: true,
        dots: true
      });

      $('.video-gallery').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
        dots: true,
        nextArrow: "<button class='video-arrow video-arrow--next'><img src='/assets/sky_arrow.svg'/></button>",
        prevArrow: "<button class='video-arrow video-arrow--prev'><img src='/assets/sky_arrow.svg'/></button>"
      });

      $('.content-cards__slider').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 2,
        centerMode: true,
        focusOnSelect: true,
        dots: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: false,
              focusOnSelect: true,
              variableWidth: true,
              dots: true
            }
          },
          {
            breakpoint: 700,
            settings: {
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: false,
              focusOnSelect: false,
              variableWidth: false,
              dots: true
            }
          }
        ]
      });
    })
  }
}
