export default class Scholarships {
  constructor() {
    this.form = $('form[form_name="Scholarship Application"]');

    if (this.form.length) {
      this.improveForm();
    }
  }

  improveForm() {
    let requiredItems = [];

    const adultDropdown = $('#form_response_answers_attributes_3_value');
    const maritalStatus = adultDropdown.parents('.dropdown-list').next();

    let adult1 = maritalStatus.nextUntil('hr').wrapAll('<div class="parent-1" />');
    adult1 = $('.parent-1');
    adult1.addClass('hidden');

    let adult2 = adult1.next().nextUntil('hr').addBack().wrapAll('<div class="parent-2" />');
    adult2 = $('.parent-2');
    adult2.addClass('hidden');

    const financialHeader = $("h4:contains('Financial Information')");
    let adultFinances1 = financialHeader.nextUntil('hr + .form-group').wrapAll('<div class="parent-finances-1" />');
    adultFinances1 = $('.parent-finances-1');
    adultFinances1.addClass('hidden');

    let adultFinances2 = adultFinances1.nextUntil('hr + .form-group').wrapAll('<div class="parent-finances-2" />');
    adultFinances2 = $('.parent-finances-2');
    adultFinances2.addClass('hidden');

    this.initRequiredAdults([adult2[0], adultFinances2[0]]);

    adultDropdown.on('change', (e) => {
      const numOfParents = e.currentTarget.value;

      this.removeRequiredAdults([adult2[0], adultFinances2[0]]);

      if (numOfParents === '1') {
        adult1.removeClass('hidden');
        adultFinances1.removeClass('hidden');
        adult2.addClass('hidden');
        adultFinances2.addClass('hidden');
      } else if (numOfParents === '2') {
        adult1.removeClass('hidden');
        adultFinances1.removeClass('hidden');
        adult2.removeClass('hidden');
        adultFinances2.removeClass('hidden');
        this.addRequiredAdults([adult2[0], adultFinances2[0]]);
      } else {
        adult1.addClass('hidden');
        adultFinances1.addClass('hidden');
        adult2.addClass('hidden');
        adultFinances2.addClass('hidden');
      }
    });

    const childrenDropdown = $('#form_response_answers_attributes_9_value');
    const childrenDropdownParent = childrenDropdown.parents('.dropdown-list');

    let child1 = childrenDropdownParent.nextUntil('hr').wrapAll('<div class="child-1" />');
    child1 = $('.child-1');
    child1.addClass('hidden');

    let child2 = child1.next().nextUntil('hr').addBack().wrapAll('<div class="child-2" />');
    child2 = $('.child-2');
    child2.addClass('hidden');

    let child3 = child2.next().nextUntil('hr').addBack().wrapAll('<div class="child-3" />');
    child3 = $('.child-3');
    child3.addClass('hidden');

    let child4 = child3.next().nextUntil('hr').addBack().wrapAll('<div class="child-4" />');
    child4 = $('.child-4');
    child4.addClass('hidden');

    let child5 = child4.next().nextUntil('hr').addBack().wrapAll('<div class="child-5" />');
    child5 = $('.child-5');
    child5.addClass('hidden');

    let child6 = child5.next().nextUntil('hr').addBack().wrapAll('<div class="child-6" />');
    child6 = $('.child-6');
    child6.addClass('hidden');

    let child7 = child6.next().nextUntil('hr').addBack().wrapAll('<div class="child-7" />');
    child7 = $('.child-7');
    child7.addClass('hidden');

    let child8 = child7.next().nextUntil('hr').addBack().wrapAll('<div class="child-8" />');
    child8 = $('.child-8');
    child8.addClass('hidden');

    let additionalChildren = child8.next().nextUntil('hr').addBack().wrapAll('<div class="child-additional" />');
    additionalChildren = $('.child-additional');
    additionalChildren.addClass('hidden');

    this.initFirstChild(child1[0]);
    this.initRequiredChildren([child2[0], child3[0], child4[0], child5[0], child6[0], child7[0], child8[0], additionalChildren[0]])

    this.hideFosterChildren([child1[0], child2[0], child3[0], child4[0], child5[0], child6[0], child7[0], child8[0]]);

    childrenDropdown.on('change', (e) => {
      const numOfChildren = e.currentTarget.value;

      $('[class*="child-"]').addClass('hidden');
      this.removeRequiredChildren([
        child2[0],
        child3[0],
        child4[0],
        child5[0],
        child6[0],
        child7[0],
        child8[0],
        additionalChildren[0]
      ]);

      if (numOfChildren === '1') {
        child1.removeClass('hidden');

      } else if (numOfChildren === '2') {
        child1.removeClass('hidden');
        child2.removeClass('hidden');
        this.addRequiredChildren([
          child2[0]
        ]);

      } else if (numOfChildren === '3') {
        child1.removeClass('hidden');
        child2.removeClass('hidden');
        child3.removeClass('hidden');
        this.addRequiredChildren([
          child2[0],
          child3[0]
        ]);

      } else if (numOfChildren === '4') {
        child1.removeClass('hidden');
        child2.removeClass('hidden');
        child3.removeClass('hidden');
        child4.removeClass('hidden');
        this.addRequiredChildren([
          child2[0],
          child3[0],
          child4[0]
        ]);

      } else if (numOfChildren === '5') {
        $('[class*="child-"]').removeClass('hidden');
        child6.addClass('hidden');
        child7.addClass('hidden');
        child8.addClass('hidden');
        additionalChildren.addClass('hidden');
        this.addRequiredChildren([
          child2[0],
          child3[0],
          child4[0],
          child5[0]
        ]);

      } else if (numOfChildren === '6') {
        $('[class*="child-"]').removeClass('hidden');
        child7.addClass('hidden');
        child8.addClass('hidden');
        additionalChildren.addClass('hidden');
        this.addRequiredChildren([
          child2[0],
          child3[0],
          child4[0],
          child5[0],
          child6[0]
        ]);

      } else if (numOfChildren === '7') {
        $('[class*="child-"]').removeClass('hidden');
        child8.addClass('hidden');
        additionalChildren.addClass('hidden');
        this.addRequiredChildren([
          child2[0],
          child3[0],
          child4[0],
          child5[0],
          child6[0],
          child7[0]
        ]);

      } else if (numOfChildren === '8') {
        $('[class*="child-"]').removeClass('hidden');
        additionalChildren.addClass('hidden');
        this.addRequiredChildren([
          child2[0],
          child3[0],
          child4[0],
          child5[0],
          child6[0],
          child7[0],
          child8[0]
        ]);

      } else if (numOfChildren === '9+') {
        $('[class*="child-"]').removeClass('hidden');
        this.addRequiredChildren([
          child2[0],
          child3[0],
          child4[0],
          child5[0],
          child6[0],
          child7[0],
          child8[0],
          additionalChildren[0]
        ]);
      }
    });

    const fosterChildSelector = $("[class *= 'child-'] .form-group:nth-of-type(2)");
    fosterChildSelector.each((i, selector) => {
      const selectorObject = $(selector);
      const selectorName = selectorObject.find('.radio:first-of-type input').attr('name');

      selectorObject
        .find(`input[type='radio'][name='${selectorName}']`)
        .on('change', (e) => {
          const selectorValue = selectorObject.find('input:checked').val();

          const caseWorkerName = selectorObject.next();
          const caseWorkerPhone = caseWorkerName.next();

          if (selectorValue === 'Yes') {
            caseWorkerName.removeClass('hidden');
            caseWorkerPhone.removeClass('hidden');

            caseWorkerName.find('input[type="text"]').attr('required', 'required');
            caseWorkerPhone.find('input[type="tel"]').attr('required', 'required');
          } else {
            caseWorkerName.addClass('hidden');
            caseWorkerPhone.addClass('hidden');

            caseWorkerName.find('input[type="text"]').removeAttr('required');
            caseWorkerPhone.find('input[type="tel"]').removeAttr('required');
          }
        });
    });

    const firstFileField = $('.file-field').first()
    const taxReturnSelector = firstFileField.prev();

    const taxReturnName = taxReturnSelector.find('.radio:first-of-type input').attr('name');

    taxReturnSelector.find(`input[type="radio"][name="${taxReturnName}"]`)
      .on('change', (e) => {
        const selectorValue = taxReturnSelector.find('input:checked').val();

        if (selectorValue === 'Online') {
          firstFileField.find('label > span:first-of-type').after('<span class="required">*</span>');
          firstFileField.find("input[type='file']").attr('required', 'required');
        } else {
          firstFileField.find('label > span:nth-of-type(2)').remove();
          firstFileField.find("input[type='file']").removeAttr('required');
        }
      });
  }

  initFirstChild(child) {
    $(child)
      .find('.form-group:nth-of-type(3) > label span, .form-group:nth-of-type(4) > label span')
      .after('<span class="required">*</span>');
  }

  hideFosterChildren(children) {
    children.forEach((child) => {
      const childObject = $(child);

      childObject
        .find('.form-group:nth-of-type(3), .form-group:nth-of-type(4)')
        .addClass('hidden');
    });
  }

  initRequiredChildren(children) {
    children.forEach((child) => {
      const childObject = $(child);

      if (childObject.find('input[type="text"]').length === 1) {
        childObject.find('label > span:first-child').after('<span class="required">*</span>');
      } else {
        childObject
          .find('.full-name .medium-4:nth-child(1) label, .full-name .medium-4:nth-child(3) label')
          .append('<span class="required">*</span>');
        childObject
          .find('.form-group:not(.radio-buttons, .full-name):nth-of-type(n + 2) > label span')
          .after('<span class="required">*</span>');
        childObject
          .find('.radio-buttons > label > span')
          .after('<span class="required">*</span>');
      }
    });
  }

  initRequiredAdults(adults) {
    adults.forEach((adult) => {
      const adultObject = $(adult);

      if (adultObject.find('.full-name').length === 1) {
        adultObject.find('.full-name > label').append('<span class="required">*</span>');
      } else {
        adultObject
          .find('.form-group:nth-of-type(1) > label span:first-child, .form-group:nth-of-type(2) > label span:first-child')
          .after('<span class="required">*</span>');
      }
    });
  }

  addRequiredChildren(children) {
    children.forEach((child) => {
      const childObject = $(child);

      if (childObject.find('input[type="text"]').length === 1) {
        childObject.find('input[type="text"]').attr('required', 'required');
      } else {
        childObject.find('.full-name:nth-child(1) .medium-4:nth-child(1) input, .full-name .medium-4:nth-child(3) input').attr('required', 'required');

        childObject.find('.form-group:not(.full-name):nth-of-type(n + 2) input[type="text"], .form-group:not(.full-name):nth-of-type(n + 2) input[type="tel"]').attr('required', 'required');
        childObject.find('.dropdown-list select').attr('required', 'required');

        if (childObject.find('.form-group:nth-of-type(2) input:checked').val() == 'Yes') {
          childObject
            .find('.form-group:nth-of-type(3), .form-group:nth-of-type(4)')
            .removeClass('hidden');
        } else {
          childObject
            .find('.form-group:nth-of-type(3), .form-group:nth-of-type(4)')
            .addClass('hidden');

          childObject
            .find('.form-group:nth-of-type(3) input[type="text"], .form-group:nth-of-type(4) input[type="tel"]')
            .removeAttr('required');
        }
      }
    });
  }

  removeRequiredChildren(children) {
    children.forEach((child) => {
      $(child).find('input[type="text"], input[type="tel"], select').removeAttr('required');
    });
  }

  addRequiredAdults(adults) {
    adults.forEach((adult) => {
      const adultObject = $(adult);

      if (adultObject.find('.full-name').length === 1) {
        adultObject.find('.full-name .medium-4:nth-child(1) input, .full-name .medium-4:nth-child(3) input').attr('required', 'required');
      } else {
        adultObject.find('input[type="text"]').attr('required', 'required');
      }
    });
  }

  removeRequiredAdults(adults) {
    adults.forEach((adult) => {
      $(adult).find('input[type="text"]').removeAttr('required');
    });
  }
}
