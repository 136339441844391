import Cookies from "js-cookie"
export default class GlobalAlert {
  constructor() {
    this.alertCookie = Cookies.get("p4-alert")
    this.close = $(".global-alert__close")
    this.alert = $(".global-alert")
    this.sessionData = $(".global-alert").data("session")
    this.link = $(".alert-link")

    this.close.on("click", () => {
      this.closeAlert()
    })

    if (this.alertCookie && this.alertCookie !== this.sessionData) {
      this.removeAlert()
    } else if (this.alert.length > 0 && !this.alertCookie) {
      this.showAlert()
    }

    this.link.on("click", () => {
      this.redirect()
    })

    $(window).on("resize", () => {
      if ($(".global-alert").hasClass("global-alert--display")) {
        const alertHeight = $(".global-alert").outerHeight()
        $("body").css("margin-top", alertHeight)
        $(".mega-menu .mega-menu__what-we-do").css("margin-top", alertHeight)
      }
    })
  }

  closeAlert() {
    Cookies.set("p4-alert", this.sessionData, { expires: 1 })
    $(".global-alert").removeClass("global-alert--display")
    $("body").css("margin-top", 0)
    $(".mega-menu .mega-menu__what-we-do").css("margin-top", 0)
  }

  removeAlert() {
    Cookies.remove("p4-alert")
    $(".global-alert").addClass("global-alert--display")

    setTimeout(() => {
      const alertHeight = $(".global-alert").outerHeight()
      $("body").css("margin-top", alertHeight)
      $(".mega-menu .mega-menu__what-we-do").css("margin-top", alertHeight)
    }, 0)
  }

  showAlert() {
    $(".global-alert").addClass("global-alert--display")

    setTimeout(() => {
      const alertHeight = $(".global-alert").outerHeight()
      $("body").css("margin-top", alertHeight)
      $(".mega-menu .mega-menu__what-we-do").css("margin-top", alertHeight)
    }, 0)
  }

  redirect() {
    Cookies.set("p4-alert", $(".global-alert").data("session"), { expires: 1 })
    $(".global-alert").removeClass("global-alert--display")
    $("body").css("margin-top", 0)
    $(".mega-menu .mega-menu__what-we-do").css("margin-top", 0)
  }
}
