export default class Location {
  constructor() {
    this.dropdown = $('.mobile-arrow');
    
    this.dropdown.on('click', () => {
      this.toggleMenu();
    });
  }

  toggleMenu() {
    $('.location-hero__menu li:nth-child(n+2)').slideToggle(300);
  }
}