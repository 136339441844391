export default class Dietary {
  constructor() {
    window.initialStyles = this.initialStyles
    window.styleForm = this.styleForm
  }

  initialStyles() {
    if (window.location.pathname === '/pages/special-dietary-needs-form') {
      var $forms = $('.form-block')
      $forms.wrap("<div class='dietary-form'></div>")

      $('.submit-button button').prop('disabled', true)

      $forms.append(
        "<span class='location-help-text'>Please Select your location before submitting.</span>",
      )

      $forms.each(function (index, form) {
        const $form = $(form)

        if (index !== 0) {
          $form.parent('.dietary-form').css('display', 'none')
        }
      })
    }
  }

  styleForm() {
    const $forms = $('form.new_form_response')

    if (window.location.pathname === '/pages/special-dietary-needs-form') {
      let formNames = []

      $forms.each((index, form) => {
        formNames.push(
          $(form).attr('form_name').replace(' - Dietary Request', ''),
        )
      })

      $forms.each(function (index, form) {
        const $form = $(form)

        let selection = `
          <div class="form-group form-select dietary-select">
            <label><span>Select your location</span><span class="required">*</span></label>
            <a class="button dropdown" aria-controls="form_${index}_dropdown" aria-expanded="false" data-dropdown="form_${index}_dropdown">
              Select your location
            </a>
            <ul aria-hidden="true" class="f-dropdown location-change" data-dropdown-content id="form_${index}_dropdown" tabindex="-1">
        `

        for (var i = 0; i < formNames.length; i++) {
          if (
            formNames[i] !==
              $form.attr('form_name').replace(' - Dietary Request', '') ||
            index == 0
          ) {
            selection += `<li>${formNames[i]}</li>`
          }
        }

        selection += `</ul></div>`

        if ($form.find('.dropdown.button').length === 0) {
          $form.prepend(selection)
        }
      })

      $('.button.dropdown').on('click', () => {
        $('.button.dropdown').siblings('ul').slideToggle()
      })

      $('.submit-button').on('click tap', (e) => {
        if ($(e.currentTarget).parent().parent().css('display') !== 'none') {
          var $verticalScrollPosition = $(e.currentTarget)
            .parent()
            .parent()
            .offset().top
          $('body,html').animate({ scrollTop: $verticalScrollPosition }, 300)
        }
      })
    }

    $('.location-change li').on('click', (e) => {
      const selectedForm = $(e.currentTarget).text()

      $('.button.dropdown').text(selectedForm).addClass('selected')

      $('.submit-button button').prop('disabled', false)

      $('.location-help-text').remove()

      $forms.each(function (index, form) {
        const target = $(form)

        if (
          target.attr('form_name').replace(' - Dietary Request', '') ==
          selectedForm
        ) {
          $('.location-change').slideUp()

          target
            .parent()
            .parent('.dietary-form')
            .css('display', 'block')
            .siblings('.dietary-form')
            .css('display', 'none')
        }
      })
    })
  }
}
