export default class Tabs {
  constructor() {
    const programs = $('.programs__list');
    const promoTabs = $('.tabbed-content');
    const childTabs = $('.children-tab');

    this.tabWidth = 0;

    this.initTabs(programs);
    this.initTabs(promoTabs);
    this.initChildTabs(childTabs, childTabs.length);

    this.initChildSelector(childTabs);

    this.initAccordion(programs);

    $(window).on('load', () => {
      $('.tab-bank--slider').slick({
        centerMode: true,
        variableWidth: true,
        focusOnSelect: true
      });
      $('.tab-bank').each((i) => {
        const tabBank = $('.tab-bank:eq('+i+ ')');
        if (tabBank.next().hasClass('tab-bank--slider')) {
          var tabSlider = tabBank.next();

          tabBank.find('.tab').each((i, tab) => {
            this.tabWidth += $(tab).outerWidth();
          });
        } else {
          var tabSlider = null;
        }


        $('.tab-bank--slider').on('beforeChange', (e, slick, currentSlide, nextSlide) => {
          const tabs = promoTabs.find('.tab');
          const content = promoTabs.find('.tab-content');
          const aboveImages = promoTabs.find('.scroll-tabs__image');

          tabs.removeClass('tab--active');
          content.removeClass('tab-content--active');
          aboveImages.stop().fadeOut(300);
          promoTabs.find(`.tab:nth-child(${nextSlide + 1})`).addClass('tab--active');
          content.eq(nextSlide).addClass('tab-content--active');
          aboveImages.eq(nextSlide).stop().fadeIn(300);
        });

        this.initTabSlider(tabBank, tabSlider);
      })
    });

    $(window).on('resize', () => {
      $('.tab-bank').each((i) => {
        const tabBank = $('.tab-bank:eq('+i+ ')');

        if (tabBank.next().hasClass('tab-bank--slider')) {
          var tabSlider = tabBank.next();
        } else {
          var tabSlider = null;
        }

        this.initTabSlider(tabBank, tabSlider);
      })
    });
  }

  initTabs(parent) {
    const tabs = parent.find('.tab');
    const content = parent.find('.tab-content');
    const aboveImages = parent.find('.scroll-tabs__image');
    const windowWidth = $(window).width();

    tabs.on('click', (e) => {
      if (this.tabWidth + 40 < windowWidth) {
        const target = $(e.currentTarget);

        content.removeClass('tab-content--active');
        tabs.removeClass('tab--active');
        aboveImages.stop().fadeOut(300);

        const tabIndex = target.index();

        parent.find(`.tab:nth-child(${tabIndex + 1})`).addClass('tab--active');
        content.eq(tabIndex).addClass('tab-content--active');
        aboveImages.eq(tabIndex).stop().fadeIn(300);
      }
    });
  }

  initAccordion(parent) {
    const tabs = parent.find('.tab');
    const content = parent.find('.tab-content');
    const toggles = parent.find('.accordion__toggle');

    tabs.on('click', (e) => {
      toggles.removeClass('accordion__toggle--active');

      const target = $(e.currentTarget);
      const tabIndex = target.index();
      toggles.eq(tabIndex).addClass('accordion__toggle--active');
    });

    toggles.on('click', (e) => {
      const target = $(e.currentTarget);

      if (target.hasClass('accordion__toggle--active')) {
        target.removeClass('accordion__toggle--active');
        content.stop().slideUp(400);
      } else {
        toggles.removeClass('accordion__toggle--active');
        tabs.removeClass('tab--active');
        target.addClass('accordion__toggle--active');

        const toggleIndex = target.data('index');
        tabs.eq(toggleIndex).addClass('tab--active');

        content.not(content[toggleIndex])
          .stop()
          .slideUp(400, function() {
            $(this).removeClass('tab-content--active');
          });

        content.eq(toggleIndex)
          .stop()
          .slideDown(400, function() {
            $(this).addClass('tab-content--active');
          });
      }
    });
  }

  initChildTabs(parent, count) {
    var i
    for (i=0; i < count; i++) {
      var current = parent[i];
      const tabs = $(current).find('.child-tab');
      const content = $(current).find('.child-content');

      tabs.on('click', (e) => {
        const target = $(e.currentTarget);

        content.removeClass('child-content--active');
        tabs.removeClass('child-tab--active');

        target.addClass('child-tab--active');

        const tabIndex = target.index();
        content.eq(tabIndex).addClass('child-content--active');
      })
    }
  }

  initChildSelector(parent) {
    const selector = parent.find('.children-selector select');
    const content = parent.find('.child-content');

    selector.on('change', (e) => {
      content.removeClass('child-content--active');

      const selectedIndex = e.currentTarget.selectedIndex;
      content.eq(selectedIndex).addClass('child-content--active');
    });
  }

  initTabSlider(tabBank, tabSlider) {
    if (tabSlider != null && tabSlider.length > 0) {
      const windowWidth = $(window).width();

      if (this.tabWidth + 40 > windowWidth) {
        tabBank.css('display', 'none');
        tabSlider.css('display', 'block');

        const activeTabIndex = tabBank.find('.tab--active').index();
        tabSlider.slick('slickGoTo', activeTabIndex);
      } else {
        tabBank.css('display', 'flex');
        tabSlider.css('display', 'none');
      }
    }
  }
}