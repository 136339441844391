import 'core-js/stable'
import 'regenerator-runtime/runtime'
import objectFitImages from 'object-fit-images'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Activities from './activities'
import Availability from './availability'
import Camps from './camps'
import Dietary from './dietary'
import { HeroMenu } from './hero'
import LifeMap from './life-map'
import Maps from './maps'
import Menu from './menu'
import Cards from './cards'
import Tabs from './tabs'
import Forms from './forms'
import Location from './location'
import Modal from './modal'
import Scholarships from './scholarships'
import Slideshow from './slideshow'
import Video from './video'
import SignUp from './sign-up'
import GlobalAlert from './global-alert'
import Donation from './donation'
import SideIndex from './side-index'
import SmoothScroll from './smooth-scroll'

objectFitImages()

new Activities()
new Availability()
new Camps()
new Dietary()
new HeroMenu()
new LifeMap()
new Maps()
new Menu()
new Modal()
new Cards()
new Tabs()
new Forms()
new Location()
new Scholarships()
new Slideshow()
new Video()
new SignUp()
new GlobalAlert()
new Donation()
new SideIndex()
new SmoothScroll()
