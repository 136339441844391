export default class Donation {
  constructor() {
    const FORM = $('.donation-form');
    const SUBMIT = $('.donation-form').find("button[type='submit']");

    SUBMIT.on('click', () => {
      event.preventDefault();
      this.dollarValidation();
    });

    // Script will need to run after EVERYTHING is loaded that will take parameters and populate the external form

    window.onload = () => { this.donationFormValueApplication(); };
  }

  dollarValidation() {
    const REGEX = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
    const REDIRECT = $('.redirect');
    const AMOUNT = $('.donation-form').find("input[name='form_response[answers_attributes][0][value]']");
    const PLAN = $('.donation-form').find("select[name='form_response[answers_attributes][1][value]']");
    const ERROR = $('.validation-errors');
    if (AMOUNT[0].value.match(REGEX) != null && PLAN[0].value != '') {
      let amount = AMOUNT[0].value;
      let plan = PLAN[0].value;
      window.location.href = `${REDIRECT[0].innerHTML}?amount=${amount}&plan=${plan}`;
    } else {
      if (PLAN[0].value == '') {
        ERROR[0].innerHTML = 'You must make a payment plan selection in order to donate';
      } else {
        ERROR[0].innerHTML = 'Please use the proper dollar formatting #.##';
      }
    }
  }

  getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
  }

  donationFormValueApplication() {
    if ( this.getUrlParameter('amount') && this.getUrlParameter('plan') && this.getUrlParameter('amount').length > 0 && this.getUrlParameter('plan').length > 0 ) {
      const DONATION = $("[id='DonationLevel_1|6$FEE']");
      const DONATIONBUTTON = $("[id='DonationLevel_1|6']");
      const ONETIMEPAYMENT = $('#payPlan_DonationLevel_oneTime');
      const ONGOINGPAYMENT = $('#payPlan_DonationLevel_ongoing');
      const AMOUNT = this.getUrlParameter('amount');
      const PLAN = this.getUrlParameter('plan');

      DONATION[0].value = AMOUNT;
      DONATIONBUTTON.prop('checked', true);
      DONATION[0].focus();
      if (PLAN == 'Monthly ongoing') {
        ONGOINGPAYMENT.prop('checked', true);
      }
    }
  }
}