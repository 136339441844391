export default class SideIndex {
  constructor() {
    this.halfScreen = $(window).height() / 2
    this.indexes = $('.side-index')

    this.indexes.each((_, sideIndex) => {
      const parent = $(sideIndex).parent()
      const anchors = parent.find('.anchor')

      anchors.each((index, anchor) => {
        const name = anchor.dataset.anchorName
        const id = anchor.id

        const link = `
          <li>
            <a href="#${id}">${name}</a>
          </li>
        `

        $(sideIndex)
          .find('ul')
          .append(link)
      })
    })

    $(window).on('load scroll resize', () => {
      this.halfScreen = $(window).height() / 2

      this.indexes.each((_, sideIndex) => {
        const parent = $(sideIndex).parent()
        const anchors = parent.find('.anchor')

        if (this.isInViewport(parent)) {
          $(sideIndex)
            .find('.side-index__container')
            .css({
              opacity: 1,
              'pointer-events': 'auto',
            })
        } else {
          $(sideIndex)
            .find('.side-index__container')
            .css({
              opacity: 0,
              'pointer-events': 'none',
            })
        }

        anchors.each((index, anchor) => {
          const sideLinks = $(sideIndex).find('a')

          if (this.isInViewport($(anchor))) {
            sideLinks.removeClass('active')
            sideLinks.eq(index).addClass('active')
          }
        })
      })
    })
  }

  isInViewport(item) {
    var elementTop = item.offset().top
    var elementBottom = elementTop + item.outerHeight()
    var viewportTop = $(window).scrollTop() - this.halfScreen
    var viewportBottom = viewportTop + $(window).height()

    return elementBottom > viewportTop && elementTop + 100 < viewportBottom
  }
}
