export default class Modal {
  constructor() {
    $('.modal-button').on('click', function() {
      const button = $(this)
      const buttonParent = button.parent()
      const modal = buttonParent.next()

      modal.fadeIn(250)
    })

    $('.modal__close, .modal__backdrop').on('click', function() {
      $(this)
        .parents('.modal-wrapper')
        .fadeOut(250)
    })

    $('.modal-wrapper').each((_, modalItem) => {
      if (modalItem.id) {
        $(`a[href="#${modalItem.id}"]`).on('click', (e) => {
          e.preventDefault()

          $(modalItem).fadeIn(250)
        })
      }
    })
  }
}
