export default class Availability {
  constructor() {
    const prevButton = $('.table-button--prev')
    const nextButton = $('.table-button--next')
    const showButton = $('.mobile-chart__button')

    const tHead = $('.availability__table thead')
    const tBody = $('.availability__table tbody')
    this.headScroll = true
    this.bodyScroll = true

    if (tHead.length) {
      $(document).on('click', 'button.square', (event) => {
        const target = $(event.target)

        $('.availability__modal').fadeOut(200)
        target.next().fadeIn(400)
      })

      $(document).on('click', '.availability__modal__backdrop', () => {
        $('.availability__modal').fadeOut(200)
      })

      $(document).on('click', '.modal__close', () => {
        $('.availability__modal').fadeOut(200)
      })

      $(document).on('click', '.mobile-chart-close', (event) => {
        const parent = event.currentTarget.parentNode
        $(parent).remove()
      })

      this.setGradients(tHead[0])

      tHead.on('scroll', (event) => this.scrollHead(event.target))
      tBody.on('scroll', (event) => this.scrollBody(event.target))

      prevButton.on('click', (event) => this.prev(event.currentTarget))
      nextButton.on('click', (event) => this.next(event.currentTarget))
      showButton.on('click', (event) => this.openChart(event.currentTarget))
    }
  }

  scrollHead(target) {
    this.setGradients(target)

    if (this.headScroll) {
      this.bodyScroll = false

      const left = target.scrollLeft
      $(target).siblings()[0].scrollLeft = left

      clearTimeout(window.isScrolling)

      window.isScrolling = setTimeout(() => {
        this.bodyScroll = true
      }, 100)
    }
  }

  scrollBody(target) {
    if (this.bodyScroll) {
      this.headScroll = false

      const left = target.scrollLeft
      $(target).siblings()[0].scrollLeft = left

      clearTimeout(window.isScrolling)

      window.isScrolling = setTimeout(() => {
        this.headScroll = true
      }, 100)
    }
  }

  setGradients(target) {
    const itemWidth = target.scrollWidth
    const parentWidth = target.parentNode.offsetWidth
    const overflow = $(target.parentNode).siblings()[0]

    if (itemWidth > parentWidth && target.scrollLeft > 0) {
      overflow.classList.add('left-shadow')
    } else {
      overflow.classList.remove('left-shadow')
    }

    if (target.scrollLeft + parentWidth + 2 < itemWidth) {
      overflow.classList.add('right-shadow')
    } else {
      overflow.classList.remove('right-shadow')
    }
  }

  prev(target) {
    const table = $(target.parentNode.nextElementSibling)
    const tbody = table.find('tbody')[0]
    const firstCellWidth = table.find('td:first-child').width()
    const toScroll = table.width() - firstCellWidth
    const currentPosition = tbody.scrollLeft

    tbody.scrollTo({
      left: currentPosition - toScroll,
      behavior: 'smooth',
    })
  }

  next(target) {
    const table = $(target.parentNode.nextElementSibling)
    const tbody = table.find('tbody')[0]
    const firstCellWidth = table.find('td:first-child').width()
    const toScroll = table.width() - firstCellWidth
    const currentPosition = tbody.scrollLeft

    tbody.scrollTo({
      left: currentPosition + toScroll,
      behavior: 'smooth',
    })
  }

  openChart(target) {
    const parent = target.parentNode
    const table = parent.parentNode

    const clone = $(table).clone()
    clone.find('thead').on('scroll', (event) => this.scrollHead(event.target))
    clone.find('tbody').on('scroll', (event) => this.scrollBody(event.target))

    clone
      .find('.table-button--prev')
      .on('click', (event) => this.prev(event.currentTarget))
    clone
      .find('.table-button--next')
      .on('click', (event) => this.next(event.currentTarget))

    $('footer.footer').after(clone)
  }
}
