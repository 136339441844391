export default class Forms {
  constructor() {
    this.retreat = $('form[form_name="Retreat Booking Form"]');
    this.retreatLocationSelector = $('select[name="form_response[answers_attributes][0][value]"]');
    this.url_string = window.location.href;
    this.url = this.url_string ? this.url_string.split('?')[1] : window.location.search.slice(1);
    this.locationSelector();
  }

  locationSelector() {
    this.retreatLocationSelector.val(decodeURI(this.url));
  }
}