export default class Activities {
  constructor() {
    this.activities = $('.activity.activity--has-description .activity__wrap');
    this.descriptions = $('.activity__description');
    this.close = $('.activity__description__close');

    this.activities.on('click', (e) => {
      $('.activity').removeClass('description-open');
      this.descriptions
        .removeClass('open')
        .removeAttr('style');

      const target = $(e.currentTarget);

      const description = target.siblings('.activity__description');
      description.addClass('open');
      description.parent().addClass('description-open');

      const position = description[0].getBoundingClientRect();
      const overhang = (position.x + 320) - $(window).width();

      if (position.x < -1) {
        description.css({
          left: (description.parent().outerWidth() / 2) - position.x
        });
      } else if (overhang > 1) {
        description.css({
          left: (description.parent().outerWidth() / 2) - overhang
        });
      }
    });

    this.close.on('click', (e) => {
      $('.activity').removeClass('description-open');

      const target = $(e.currentTarget);
      target.parent()
        .removeClass('open')

      setTimeout(() => {
        target.parent().removeAttr('style');
      }, 300);
    })
  }
}