export default class Menu {
  constructor() {
    this.mobileDropdown = $(
      '.top__main-nav--mobile li > a[href="/pages/programs"]',
    )
    this.mobilePrograms = $('.mobile-programs')
    this.menuButton = $('.nav__menu-btn')
    this.menu = $('.mobile-menu__dropdown')
    this.mobileMenu = $('.mobile-menu')

    $(window).on('load', () => {
      $('.mobile-main-menu .mobile-arrow').click(function(e) {
        $(this).toggleClass('arrow-rotation')
        $(this)
          .next()
          .slideToggle()
      })
    })

    $('#about').on('mouseenter', function() {
      $('.mega-menu__about').addClass('open')
      $('.mega-menu__what-we-do.open').removeClass('open')
    })

    $('#what-we-do').on('mouseenter', function() {
      $('.mega-menu__what-we-do').addClass('open')
      $('.mega-menu__about.open').removeClass('open')
    })

    $('#about').on('mouseleave', function() {
      $('.mega-menu__about.open').removeClass('open')
    })

    $('#what-we-do').on('mouseleave', function() {
      $('.mega-menu__what-we-do.open').removeClass('open')
    })

    this.menuButton.on('click', () => {
      this.toggleMenu()
    })

    this.upArrow()
  }

  toggleMenu() {
    this.menuButton.toggleClass('nav__menu-btn--active')
    this.menu.slideToggle(300)
    window.scrollTo(0, 0)
    this.mobileMenu.scrollTop(0)
    this.mobileMenu.toggleClass('mobile-menu--open')
    $('body').toggleClass('mobile-mega-menu--open')
  }

  upArrow() {
    const TOTOP = $('.up-arrow')
    TOTOP.on('click', () => {
      $('html, body').animate({ scrollTop: 0 }, 'slow')
      return false
    })
    $(window).scroll(function() {
      if ($(window).scrollTop() + $(window).height() > $('body').height() / 2) {
        TOTOP.removeClass('hidden')
      } else {
        if (!TOTOP.hasClass('hidden')) {
          TOTOP.addClass('hidden')
        }
      }
    })
  }
}
