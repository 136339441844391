export class HeroMenu {
  constructor() {
    $(window).on('load', () => {
      this.heroMenu = $('.hero-menu');

      this.desktopMenu = $(".hero-menu:not(.hero-menu--collapsed)");

      if (this.heroMenu.length > 0) {
        const buttonWidth = this.heroMenu.find('.button').outerWidth(),
          navWidth = this.heroMenu.find('> .nav').outerWidth();

        const leftPad = parseInt(this.heroMenu.css('padding-left'), 10),
          rightPad = parseInt(this.heroMenu.css('padding-right'), 10)
        if (buttonWidth == undefined) {
          this.menuWidth = navWidth + leftPad + rightPad;
        } else {
          this.menuWidth = navWidth + buttonWidth + leftPad + rightPad;
        }
      }

      this.programHero = $(".program-hero");
      this.sectionHero = $(".section-hero");
      this.locationHero = $(".hero__location");
      this.infoCallout = $(".info-callout");

      this.viewEdge();
      this.toggleHeroMenuSize();
    });

    $(window).on('resize', () => {
      this.toggleHeroMenuSize();
    });

    this.menuToggle = $('.hero-menu__menu-toggle');

    this.menuToggle.on('click', (e) => {
      this.menuToggle.toggleClass('open');
      this.heroMenu.find('> .nav').stop().slideToggle(300);
    });
  }

  toggleHeroMenuSize() {
    if (this.heroMenu && this.heroMenu.length > 0) {
      const windowWidth = $(window).width();

      const body = $('body');
      const callout = $('.info-callout');

      if (this.menuWidth > windowWidth) {
        this.heroMenu.addClass('hero-menu--collapsed');
      } else {
        this.heroMenu.removeClass('hero-menu--collapsed');
      }
    }
  }

  viewEdge() {
    if ($('body').hasClass('template-program_landing') && this.infoCallout.length > 0) {
      this.programHero.addClass('program-hero--viewable');
    }
    // if ($('body').hasClass('template-for_parents') && this.infoCallout.length > 0) {
    //   this.sectionHero.addClass('section-hero--viewable');
    // }
    if ($('body').hasClass('template-blog')) {
      this.sectionHero.addClass('section-hero--noview')
    }
    if ($('body').hasClass('template-location_landing') && this.infoCallout.length > 0) {
      this.locationHero.addClass('hero__location--viewable')
    }
  }
}