import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

export default class Maps {
  constructor() {
    this.map = $('#map')
    this.pointsOfInterest = $('.point-of-interest')
    this.details = $('.point-of-interest__details')

    this.markerIcon = new L.Icon({
      iconUrl: '/assets/map_pin.svg',
      iconSize: [48, 64],
      iconAnchor: [24, 58],
    })

    this.markerIconHighlight = new L.Icon({
      iconUrl: '/assets/map_pin_highlight.svg',
      iconSize: [48, 64],
      iconAnchor: [24, 58],
    })

    this.markerIconLarge = new L.Icon({
      iconUrl: '/assets/map_pin_large.svg',
      iconSize: [58, 106],
      iconAnchor: [29, 90],
    })

    this.markers = []

    if (this.map.length) {
      this.initMap()
    }

    $('.point-of-interest__image').on('click', (e) => {
      const target = $(e.currentTarget)
      this.toggleDetails(target, true)
    })

    this.pointsOfInterest.hover(
      (e) => {
        this.iconHover(e, true)
      },
      (e) => {
        this.iconHover(e, false)
      },
    )

    $('.point-of-interest__details-toggle').on('click', (e) => {
      this.details.stop().slideUp(400)
      this.markers.forEach((marker) => {
        marker.setIcon(this.markerIcon)
      })
    })

    this.poiList = $('.location-map__poi-list')
    this.noSelection = $('.poi-dropdown__no-selection')
    this.poiSelection = $('.poi-dropdown__selection')
    this.poiOptions = $('.poi-dropdown__options')

    $('.poi-dropdown__no-selection, .poi-dropdown__selection').on(
      'click',
      () => {
        this.poiOptions.slideToggle(400)
      },
    )

    $('.poi-dropdown__option').on('click', (e) => {
      const target = $(e.currentTarget)

      const markerId = target.data('marker')

      const targetPoi = $(
        `.point-of-interest[data-marker="${markerId}"] .point-of-interest__image`,
      )

      this.toggleDetails(targetPoi)
    })
  }

  initMap() {
    let locationMap = L.map('map', {
      crs: L.CRS.Simple,
      center: [0, 0],
      zoom: 0,
      minZoom: 0,
      maxZoom: 1,
      maxBoundsViscosity: 0.85,
      zoomControl: false,
      scrollWheelZoom: false,
    })

    const h = parseInt(this.map.data('height'), 10),
      w = parseInt(this.map.data('width'), 10)

    const southWest = locationMap.unproject([0, h], locationMap.getMaxZoom())
    const northEast = locationMap.unproject([w, 0], locationMap.getMaxZoom())

    const bounds = new L.LatLngBounds(southWest, northEast)

    const tilePath = this.map.data('path')

    if (tilePath.match(/_new/)) {
      L.tileLayer(`/assets/tiles/${tilePath}/{z}/{y}/{x}.png`, {
        noWrap: true,
        bounds: bounds,
      }).addTo(locationMap)
    } else {
      L.tileLayer(`/assets/tiles/${tilePath}/{z}/{x}/{y}.png`, {
        noWrap: true,
        bounds: bounds,
      }).addTo(locationMap)
    }

    L.control.zoom({ position: 'bottomleft' }).addTo(locationMap)

    locationMap.setMaxBounds(bounds)

    const centerPoint = bounds.getCenter()
    locationMap.setView(centerPoint, 0)

    this.setUpMarkers(locationMap)
  }

  toggleDetails(target, slide, scrollDetails) {
    const windowWidth = $(window).width()

    const markerId = target.parent().data('marker')

    let selectedMarker = []

    this.markers.forEach((marker) => {
      marker.setIcon(this.markerIcon)
      selectedMarker = this.markers.filter((pin) => pin.id === markerId)
    })

    if (selectedMarker.length) {
      selectedMarker = selectedMarker[0]
    }

    if (windowWidth >= 940) {
      const targetDetails = target.siblings('.point-of-interest__details')

      if (targetDetails.length) {
        if (!scrollDetails) {
          this.details
            .stop()
            .slideUp(400)
            .removeClass('open')

          this.slideDetails(targetDetails, selectedMarker)
        } else {
          const points = $('.location-map__points')
          const offset = windowWidth >= 960 ? 89 : 0

          if ($('.point-of-interest__details.open').length > 0) {
            $('.point-of-interest__details.open')
              .stop()
              .removeClass('open')
              .slideUp(400, () => {
                $('html, body')
                  .animate(
                    {
                      scrollTop:
                        points.scrollTop() + target.offset().top - offset,
                    },
                    600,
                  )
                  .promise()
                  .then(() => {
                    this.slideDetails(targetDetails, selectedMarker)
                  })
              })
          } else {
            $('html, body')
              .animate(
                {
                  scrollTop: points.scrollTop() + target.offset().top - offset,
                },
                600,
              )
              .promise()
              .then(() => {
                this.slideDetails(targetDetails, selectedMarker)
              })
          }
        }
      }
    } else {
      this.poiOptions.hide()
      this.noSelection.hide()

      this.poiSelection.text(target.text()).show()

      this.poiList.addClass('has-selection')

      this.pointsOfInterest.hide()
      this.details.hide()

      target.parent().show()
      target.siblings('.point-of-interest__details').show()

      let map = ''

      if (selectedMarker) {
        selectedMarker.setIcon(this.markerIconLarge)

        map = selectedMarker._map
        map.panTo(selectedMarker._latlng)
      }

      if (!$('.location-map__map').hasClass('has-selection')) {
        $('.location-map__map').addClass('has-selection')

        setTimeout(function() {
          map.invalidateSize()
        }, 400)
      }

      if (slide) {
        const headerOffset = windowWidth < 700 ? 0 : 89
        $('html, body').animate(
          { scrollTop: target.offset().top - headerOffset },
          600,
        )
      }
    }
  }

  slideDetails(targetDetails, selectedMarker) {
    if (targetDetails.is(':visible')) {
      targetDetails
        .removeClass('open')
        .stop()
        .slideUp(400)

      if (selectedMarker) {
        selectedMarker.setIcon(this.markerIcon)
      }
    } else {
      targetDetails
        .addClass('open')
        .stop()
        .slideDown(400)

      if (selectedMarker) {
        selectedMarker.setIcon(this.markerIconLarge)

        const map = selectedMarker._map

        map.panTo(selectedMarker._latlng)
      }
    }
  }

  setUpMarkers(map) {
    this.pointsOfInterest.each((_, point) => {
      const poi = $(point)

      const markerId = poi.data('marker'),
        x = poi.data('x'),
        y = poi.data('y')

      if (x && y) {
        const markerLocation = map.unproject([x, y], map.getMaxZoom())

        const newMarker = L.marker(markerLocation, {
          icon: this.markerIcon,
        }).addTo(map)

        newMarker.id = markerId

        newMarker.on('mouseover', (e) => {
          const currentIcon = e.target.options.icon.options.iconUrl

          if (currentIcon !== '/assets/map_pin_large.svg') {
            e.target.setIcon(this.markerIconHighlight)
          }
        })

        newMarker.on('mouseout', (e) => {
          const currentIcon = e.target.options.icon.options.iconUrl

          if (currentIcon !== '/assets/map_pin_large.svg') {
            e.target.setIcon(this.markerIcon)
          }
        })

        newMarker.on('click', (e) => {
          const selectedId = e.target.id

          const selectedPOI = $(
            `.point-of-interest[data-marker='${selectedId}']`,
          )
          this.toggleDetails(
            selectedPOI.find('.point-of-interest__image'),
            false,
            true,
          )
        })

        this.markers.push(newMarker)
      }
    })
  }

  iconHover(e, over) {
    const target = $(e.currentTarget)
    const markerId = target.data('marker')

    let selectedMarker = []

    this.markers.forEach((marker) => {
      selectedMarker = this.markers.filter((pin) => pin.id === markerId)

      if (selectedMarker.length) {
        selectedMarker = selectedMarker[0]
      }
    })

    if (selectedMarker) {
      const currentIcon = selectedMarker.options.icon.options.iconUrl

      if (over) {
        if (currentIcon !== '/assets/map_pin_large.svg') {
          selectedMarker.setIcon(this.markerIconHighlight)
        }
      } else {
        if (currentIcon !== '/assets/map_pin_large.svg') {
          selectedMarker.setIcon(this.markerIcon)
        }
      }
    }
  }
}
