export default class Camps {
  constructor() {
    $('.camp-options select').on('change', (e) => {
      const selected = e.target.value

      if (!selected) {
        $('.option-card-hover').css('display', 'block')
      } else {
        $('.option-card-hover').each((_, option) => {
          const grades = option.dataset.grades.split(',')

          if (grades.includes(selected)) {
            $(option).css('display', 'block')
          } else {
            $(option).css('display', 'none')
          }
        })
      }
    })
  }
}
