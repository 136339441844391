export default class SignUp {
  constructor() {
    $(window).on('load', () => {
      this.signUp = $('.sign-up');
      this.media = $ ('.media');
      if ($('.below-content').length > 0) {
        this.belowContent = $('.below-content');
      }
      this.noEdge();
    });
  }

  noEdge() {
    if ($('body').hasClass('template-location_landing')) {
      this.signUp.addClass('no-edge');
      this.media.last().addClass('no-gap');
    }
    if (this.belowContent && this.belowContent.length > 0) {
      if (this.belowContent.children().last().hasClass('sign-up') && this.belowContent.children().last().prev().hasClass('fullwidth-cta')) {
        this.belowContent.children().last().prev().addClass('no-gap__cta');
        this.signUp.addClass('no-edge');
        var background = this.signUp.attr( "background-image" );
        this.signUp.attr('style', 'background-image:' + background);
      }
      if (this.belowContent.children().last().hasClass('fullwidth-cta')) {
        this.belowContent.children().last().addClass('no-gap__cta');
      }
    }
  }
}







    