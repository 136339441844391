export default class LifeMap {
  constructor() {
    this.arrow = $('.arrow');
    this.circle = $('.circle');
    this.triangle = $('.triangle');
    
    this.arrow.mouseenter(() => {
      this.lifeMapHover('arrow');
    });

    this.circle.mouseenter(() => {
      this.lifeMapHover('circle');
    });

    this.triangle.mouseenter(() => {
      this.lifeMapHover('triangle');
    });

    this.arrow.mouseleave(() => {
      $('.icon--open').removeClass('icon--open');
    });

    this.circle.mouseleave(() => {
      $('.icon--open').removeClass('icon--open');
    });

    this.triangle.mouseleave(() => {
      $('.icon--open').removeClass('icon--open');
    });
  }

  lifeMapHover(shape) {
    $('.background--open').removeClass('background--open');
    $('.background__' + shape).addClass('background--open');
    $('.' + shape + '_icon').addClass('icon--open');
  }
}