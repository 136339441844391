export default class Cards {
  constructor() {
    this.signUp = $('.sign-up');
    this.programCards = $('.program-child');
    if (this.programCards.length >= 3) {
      this.edgechange();
    }
  }

  edgechange() {
    this.signUp.addClass('no-color');
  }
}